import React from 'react';
import styled from '@emotion/styled';

import Segment from 'Components/segment';
import Row from 'Components/row';

import { responsiveFont } from 'Styles/helpers';
import { max1280, max1025 } from 'Utils/variables';
import { H1, Copy } from 'Styles/typography';

const Container = styled.div`
  box-shadow: 0px 1px 2px 1px rgb(0 0 0 / 15%);
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 605px;
`;

const Hero = styled.div`
  overflow: hidden;
  padding: 24px;

  @media (max-width: ${max1280}) {
    padding: 0px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 16px;
    img {
      border-radius: 8px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  @media (min-width: ${max1280}) {
    img {
      border-radius: 8px;
    }
  }
`;

const Header = styled(H1)`
  ${responsiveFont({ sizes: '28,36' })};
`;

export const AppLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
  max-width: 100%;

  @media (min-width: ${max1025}) {
    margin-top: 40px;
    width: 50%;
  }

  @media (min-width: ${max1025}) {
    align-items: center;
    width: 100%;
  }
`;

export const AppLink = styled.a`
  display: inline-block;
  height: auto;

  img {
    height: auto;
    max-height: 48px;
    width: auto;
  }

  & + & {
    margin-left: 10px;
  }

  @media (min-width: ${max1025}) {
    & + & {
      margin-left: 20px;
    }
  }
`;

const CopyContainer = styled.div`
  width: 100%;
  padding: 0px 24px;
  text-align: left;
  @media (max-width: ${max1280}) {
    text-align: center;
    padding: 0px 8px;
  }
`;

const Apps = () => {
  return (
    <>
      <Segment paddingTop={200} paddingBottom={200} mobilePaddingTop={0} mobilePaddingBottom={100}>
        <Row>
          <Container>
            <Hero>
              <img src="/static/InHome-App.png" />
            </Hero>
            <CopyContainer>
              <Header>Get inside with the InHome app</Header>
              <Copy>You'll need to download the Walmart InHome app to do this.</Copy>
            </CopyContainer>
            <AppLinks>
              <AppLink href="https://apps.apple.com/us/app/walmart-inhome-delivery/id1479784568?mt=8">
                <img alt="Download on the Apple Store" src="/static/app-store.png" />
              </AppLink>
              <AppLink href="https://play.google.com/store/apps/details?id=com.walmart.inhome&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img alt="Get it on Google Play" src="/static/play-store.png" />
              </AppLink>
            </AppLinks>
          </Container>
        </Row>
      </Segment>
    </>
  );
};

export default Apps;
